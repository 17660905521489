import { identity, mapObjIndexed, split } from "ramda";
import { isNotNilOrEmpty, isUndefined } from "ramda-adjunct";

export type EnvValue = string | number | boolean | string[];

type ParseNonNullable<T extends EnvValue> = (name: string, value?: string) => T;
type ParseNullable<T extends EnvValue> = (
  name: string,
  value?: string
) => T | null;

type OptsRequired<R extends boolean> = { required: R };
type OptsDefault<T extends EnvValue> = { defaultTo: T };

interface CreateParser<T extends EnvValue> {
  (): ParseNullable<T>;

  (opts: OptsRequired<false>): ParseNullable<T>;

  (opts: OptsRequired<true>): ParseNonNullable<T>;

  (opts: OptsDefault<T>): ParseNonNullable<T>;
}

type Parser<T extends EnvValue> = ParseNullable<T> | ParseNonNullable<T>;

type Deserializer<T extends EnvValue> = (value: string) => T;

const isSet = (input?: EnvValue): input is EnvValue => isNotNilOrEmpty(input);

function defineParser<T extends EnvValue>(
  parseInput: Deserializer<T>
): CreateParser<T> {
  const createNullable: () => ParseNullable<T> = () => (name, value) =>
    isSet(value) ? parseInput(value) : null;

  const createDefault: (opts: OptsDefault<T>) => ParseNonNullable<T> = ({
    defaultTo,
  }) => (name, value) => (isSet(value) ? parseInput(value) : defaultTo);

  const createRequired: <R extends boolean>(
    opts: OptsRequired<R>
  ) => ParseNullable<T> | ParseNonNullable<T> = ({ required }) =>
    required
      ? (name, value) => {
          if (isSet(value)) return parseInput(value);
          throw new Error(`Required environment variable not set: ${name}`);
        }
      : createNullable();

  function createParser(): ParseNullable<T>;
  function createParser(opts: OptsRequired<false>): ParseNullable<T>;
  function createParser(opts: OptsRequired<true>): ParseNonNullable<T>;
  function createParser(opts: OptsDefault<T>): ParseNonNullable<T>;
  function createParser(
    opts?: OptsRequired<boolean> | OptsDefault<T>
  ): Parser<T> {
    if (isUndefined(opts)) return createNullable();
    if ("required" in opts) return createRequired(opts);
    if ("defaultTo" in opts) return createDefault(opts);
    throw new Error("Invalid parser options");
  }

  return createParser;
}

const jsonParse = (value: string) => JSON.parse(value);

export const string = defineParser<string>(identity);
export const number = defineParser<number>(jsonParse);
export const boolean = defineParser<boolean>(jsonParse);
export const stringArray = defineParser<string[]>(split(","));

type ParserMap = Record<
  string,
  Parser<string> | Parser<number> | Parser<boolean> | Parser<string[]>
>;

type Parsed<T extends ParserMap> = {
  [K in keyof T]: ReturnType<T[K]>;
};

export function loadEnv<T extends ParserMap>(
  source: Record<string, string | undefined>,
  types: T
): Parsed<T> {
  return mapObjIndexed(
    (parser, name) => parser(name, source[name]),
    types
  ) as Parsed<T>;
}
