/* global WEBPACK_DEFINE_PARAMS_PLACEHOLDER */

import { RemoteBackendConfig } from "@sinch/core";
import { boolean, loadEnv, number, string } from "@sinch/env";
import { Nullable } from "@sinch/types";

/**
 * All environment variables are collocated into single file
 * so it can be easily extracted and replaced using envsubst
 * when starting a container.
 *
 * @overview
 */

/**
 * Placeholder values are replaced by Webpack build and/or envsubst.
 */
/* @ts-expect-error */
const initParams = WEBPACK_DEFINE_PARAMS_PLACEHOLDER;

/**
 * Only for local usage, do not export for application code.
 *
 * Define explicit constants DEVELOPMENT and PRODUCTION in order to support
 * proper code minification in application code (eg. various debug messages etc)
 */
function getEnv(): string | null {
  const { mode } = loadEnv(initParams, {
    mode: string(),
  });

  return mode;
}

export function getGitRevisionInfo(): {
  branch: Nullable<string>;
  date: Nullable<string>;
  version: Nullable<string>;
} {
  const { GIT_BRANCH: branch, GIT_DATE: date, GIT_VERSION: version } = loadEnv(initParams, {
    GIT_BRANCH: string(),
    GIT_DATE: string(),
    GIT_VERSION: string(),
  });

  return { branch, date, version };
}

export function getReactRenderTarget(): string {
  const { REACT_MOUNT_POINT } = loadEnv(initParams, {
    REACT_MOUNT_POINT: string({ required: true }),
  });

  const { REACT_RENDER_TARGET } = loadEnv(initParams, {
    REACT_RENDER_TARGET: string({ defaultTo: REACT_MOUNT_POINT }),
  });

  return REACT_RENDER_TARGET;
}

export function getRouterBasePath(): string {
  return loadEnv(initParams, {
    ROUTER_BASE_PATH: string({ defaultTo: "" }),
  }).ROUTER_BASE_PATH;
}

export function getBackendParams(): RemoteBackendConfig {
  const { BACKEND_REMOTE_TIMEOUT_MS: timeoutMs, BACKEND_REMOTE_URL: url } = loadEnv(initParams, {
    BACKEND_REMOTE_TIMEOUT_MS: number({ defaultTo: 30000 }),
    BACKEND_REMOTE_URL: string({ defaultTo: "http://localhost" }),
  });

  return {
    timeout: timeoutMs,
    url,
  };
}

export function getMapApiKey() {
  const { MAP_API_KEY } = loadEnv(initParams, {
    MAP_API_KEY: string(),
  });

  return MAP_API_KEY;
}
export function getMapyCzApiKey() {
  const { MAPYCZ_API_KEY } = loadEnv(initParams, {
    MAPYCZ_API_KEY: string(),
  });

  return MAPYCZ_API_KEY;
}

export function getErrorMonitorParams() {
  const { ERROR_MONITOR_API_KEY, ERROR_MONITOR_DEBUG, ERROR_MONITOR_ENV } = loadEnv(initParams, {
    ERROR_MONITOR_API_KEY: string(),
    ERROR_MONITOR_DEBUG: boolean(),
    ERROR_MONITOR_ENV: string(),
  });

  return { ERROR_MONITOR_API_KEY, ERROR_MONITOR_DEBUG, ERROR_MONITOR_ENV };
}

export function getGTMParams() {
  const { GTM_ID } = loadEnv(initParams, {
    GTM_ID: string(),
  });

  return { GTM_ID };
}
